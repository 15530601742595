document.addEventListener('DOMContentLoaded', function () {
  var buttons = document.getElementsByClassName('showCatsButton');

  Array.from(buttons).forEach(function(button) {
    button.addEventListener('click', function() {
      var targetBoxId = this.getAttribute('data-box');
      var targetBox = document.getElementById(targetBoxId);

      // Hide or show the target box
      targetBox.classList.toggle('hidden');
      
      // Toggle dark mode
      document.body.classList.toggle('dark-mode');
      targetBox.classList.toggle('dark-mode');
    });
  });
});
